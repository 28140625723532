import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";

import BackgroundImg from "images/pred_back.jpg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { verifyEmail } from "services/auth";

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("Verifying...");

  useEffect(() => {
    verifyEmail(token)
      .then((res) => {
        setMessage(res.message);
        // setTimeout(() => navigate("/login"), 3000);
      })
      .catch(() => {
        setMessage("Invalid or expired token.");
      });
  }, [token, navigate]);

  return (
    <div
      className="nc-PageVerify min-h-[calc(100vh-5rem)] flex justify-center items-center"
      data-nc-id="PageVerify"
    >
      <Helmet>
        <title>Email Verification || TorchEvents</title>
      </Helmet>
      <div className="xs:mb-16 my-4 mx-4 xs:mx-8 sm:my-8 flex flex-col sm:flex-row shadow-[0_10px_34px_-15px_rgba(0,0,0,0.24)] rounded w-full sm:mx-0 sm:w-4/5 md:w-3/4 lg:w-2/3">
        {/* Left Section (Background Image) */}
        <div
          className="w-full sm:w-1/2 md:w-2/5 min-h-[200px] p-4 bg-cover text-white rounded-t sm:rounded-l sm:rounded-tr-none"
          style={{ backgroundImage: `url(${BackgroundImg})` }}
        ></div>

        {/* Right Section (Message Display) */}
        <div className="mx-auto w-full sm:w-1/2 md:w-3/5 space-y-6 py-6 px-8 text-center">
          <h2 className="text-2xl font-semibold text-neutral-800 dark:text-white">
            {message}
          </h2>
          <ButtonPrimary onClick={() => navigate("/login")}>
            Go to Login
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
