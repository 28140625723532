import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";

export interface MainNavProps {
  isTop: boolean;
}

const MainNav: FC<MainNavProps> = ({ isTop }) => {
  const user: any = localStorage.getItem('user');

  return (
    <div
      className={`nc-MainNav1 relative z-10 ${isTop ? "onTop " : "notOnTop backdrop-filter"
        }`}
    >
      <div className="container py-0 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          {/* <div className="hidden items-center xl:flex space-x-1">
            <SwitchDarkMode />
            <SearchDropdown />
            <div className="px-1" />
            <ButtonPrimary href="/login">Sign In</ButtonPrimary>
          </div> */}
          <div className="flex items-center lg:hidden">
            {/* <SwitchDarkMode /> */}
            <div className="px-1" />
            <MenuBar />
          </div>
          {
            user && user > 0 ?
              (<div className="flex-1 flex items-center justify-end ">
                <AvatarDropdown />
              </div>) : (
                <>
                  <div className="h-20 flex-shrink-0 flex items-center">
                    <span className="text-gray-500">Exhibitor Services:</span>
                    <a
                      className="inline-flex items-center text-sm lg:text-[15px] font-medium text-slate-700 dark:text-slate-300 py-2.5 px-2 xl:px-3 rounded-full hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200 "
                      href={"/login"}
                    >
                      Sign In
                    </a>
                    <a
                      className="inline-flex items-center text-sm lg:text-[15px] font-medium text-slate-700 dark:text-slate-300 py-2 px-4 xl:px-3 rounded-full hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200 "
                      href={"/signup"}
                    >
                      Sign Up
                    </a>
                  </div>
                  {/* <ButtonSecondary href="/login" className="border-none" sizeClass="px-2 py-3">Sign In</ButtonSecondary>
                <ButtonSecondary href="/signup" className="border-none" sizeClass="px-2 py-3">Sign Up</ButtonSecondary> */}
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default MainNav;
