import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import logoImg from "images/logo.png";
import whiteLogoImg from "images/logo_white.png";
import logoLightImg from "images/logo.png"; // logo-right.png
import { globalCurrentEventId } from "utils/global";
import { getEventById, getExhibitionData } from "services/events";
export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  isWhite?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "flex-shrink-0",
  isWhite = "black"
}) => {
  const [searchParams] = useSearchParams();
  const eventParam = searchParams.get("event");
  const location = useLocation();
  const [logo, setLogo] = useState(img);
  const [type, setType] = useState<string>('');

  const getIdFromLocation = (): string | null => {
    const event_id = globalCurrentEventId();
    const pathParts = location.pathname.split("/");

    if ( location.pathname == "/" || location.pathname == "/events" || location.pathname == "/dashboard" || pathParts.includes("signup") || pathParts.includes("login")) {
      return null;
    }

    if (pathParts.includes("floorplans")) {
      setType('floorplans');
      return pathParts[pathParts.indexOf("floorplans") + 1];
    }

    if (pathParts.includes("exhibitors")) {
      setType('exhibitors');
      return pathParts[pathParts.indexOf("exhibitors") + 1];
    }

    if (event_id) {
      setType('admin-exhibitor');
      return event_id?.toString();
    }

    return null;
  };

  // Use useEffect to handle fetching data when location changes
  useEffect(() => {

    const id = getIdFromLocation(); // Get the ID based on location after each render

    const fetchEvent = async () => {
      try {
        let data = null;

        if (type === 'floorplans') {
          data = await getEventById(id || '', 'event'); // event // map
        } else if (type === 'exhibitors') {
          data = await getExhibitionData(id || '');
        } else if (type === 'admin-exhibitor') {
          data = await getEventById(id || '', 'event');
        }

        if (data && data?.event?.image) {
          setLogo(`${process.env.REACT_APP_BACKEND_URL}/${data?.event?.image}`);
        } else {
          setLogo(img);
        }

      } catch (err) {
        console.error("Failed to fetch event:", err);
      }
    };

    // Only fetch event if the ID exists
    // if (id) {
      fetchEvent();
    // }
  }, [location.pathname, type, eventParam]);

  useEffect(() => {
    
  }, [logo]);

  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-slate-600 ${className}`}
    >
      {logo ? (
        <img
          key={logo}
          className={`block max-h-[50px] sm:max-h-[70px] ${imgLight ? "dark:hidden" : ""
            }`}
          src={isWhite == "black" ? logo : imgLight}
          alt="Logo"
          onError={() => console.log("Error loading image:", logo)}
        />
      ) : (
        "Logo Here"
      )}

      {/* {imgLight && (
        <img
          className="hidden max-h-8 sm:max-h-10 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )} */}
    </Link>
  );
};

export default Logo;
